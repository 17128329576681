import { signIn, useSession } from 'next-auth/react';
import { useEffect } from 'react';

import DashboardScreen from '../../scenes/dashboard/DashboardScreen';

const DashboardScreenContainer = () => {
  const { data: session, status } = useSession();

  useEffect(() => {
    if (session?.error === 'RefreshAccessTokenError' || status === 'unauthenticated') {
      signIn(); // Force sign in to hopefully resolve error
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.error, status]);

  return <DashboardScreen status={status} />;
};

export default DashboardScreenContainer;
