import PropTypes from 'prop-types';
import React from 'react';
import { Card, Container, Flex, Grid, IconButton, Spinner, Text, ThemeProvider } from 'theme-ui';

import { Layout, Link, SEO } from '../../../components';
import { STRINGS as COMMON_STRINGS, MENU_ITEMS } from '../../../constants';
import theme from '../../../theme';
import styles from './DashboardScreen.style';

const DashboardScreen = (props) => {
  const { status } = props;

  const renderCard = (item) => {
    const { url, icon: Icon, name } = item;
    return (
      <Link key={name} path={url} href={url} sx={styles.link}>
        <Card key={name} variant="dashboardCard">
          <Flex sx={styles.link.container}>
            <IconButton aria-label={name} sx={styles.link.icon}>
              <Icon size={48} />
            </IconButton>
            <Text sx={styles.link.label}>{name}</Text>
          </Flex>
        </Card>
      </Link>
    );
  };

  const PanelGrid = () => {
    return (
      <Grid sx={styles.grid}>
        {MENU_ITEMS.slice(1).map((item) => {
          return renderCard(item);
        })}
      </Grid>
    );
  };

  if (status === 'authenticated') {
    return (
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title={COMMON_STRINGS.seo.panel} />
          <Container sx={styles.container}>
            <PanelGrid />
          </Container>
        </Layout>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title={COMMON_STRINGS.seo.panel} />
        <Container sx={styles.container}>
          <Flex sx={styles.spinnerContainer}>
            <Spinner size={60} />
          </Flex>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

DashboardScreen.propTypes = {
  status: PropTypes.string.isRequired,
};

export default DashboardScreen;
