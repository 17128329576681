export default {
  container: {
    mt: '1rem',
  },
  spinnerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
  },
  grid: {
    gridTemplateColumns: ['repeat(1,1fr)', null, null, null, 'repeat(2,1fr)'],
  },
  link: {
    color: 'text',
    textDecoration: 'none',
    transition: '0.5s',
    fontSize: '20px',
    container: {
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    label: {
      textDecoration: 'none',
    },
    icon: {
      size: 48,
      color: 'text',
      cursor: 'pointer',
      mx: '0.5rem',
    },
  },
};
